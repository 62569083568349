<template>
  <div class="param-body">
    <div class="first-part">
      <div class="company-block">
        <div class="block-header">
          <div class="left">
            <div class="icon">
              <i class="ik ik-home"></i>
            </div>
            <div class="header-info">
              <span>Entreprise</span>
              <span>{{currentCompany.nom}}</span>
            </div>
          </div>
          <div
            class="right"
            title="Modifier"
            v-if="!companySettingState"
            @click="companySettingState = true"
          >
            <i class="ik ik-edit-1"></i>
          </div>
          <div class="confirm" v-else>
            <div @click="companySettingState = false" title="annuler">
              <i class="ik ik-x"></i>
            </div>
            <div title="enregistrer">
              <i class="ik ik-check"></i>
              <span>Valider</span>
            </div>
          </div>
        </div>
        <div class="block-main" v-if="!companySettingState">
          <ul>
            <li>
              <span>Téléphone</span>
              <span>{{currentCompany.tel}}</span>
            </li>
            <li>
              <span>Email</span>
              <span>{{currentCompany.email}}</span>
            </li>
            <li>
              <span>Siège sociale</span>
              <span>Yaoundé, Mvan Cameroon</span>
            </li>
            <li>
              <span>Registre du commerce</span>
              <span>Non renseignée</span>
            </li>
          </ul>
        </div>
        <div class="block-main" v-else>
          <ul>
            <li>
              <span>Téléphone</span>
              <input type="text" :value="currentCompany.tel" />
            </li>
            <li>
              <span>Email</span>
              <input type="text" :value="currentCompany.email" />
            </li>
            <li>
              <span>Siège sociale</span>
              <input type="text" value="Yaoundé, Mvan Cameroon" />
            </li>
            <li>
              <span>Registre du commerce</span>
              <input type="text" value="Non renseigné" />
            </li>
          </ul>
        </div>
      </div>
      <div class="boss-block">
        <div class="block-header">
          <div class="left">
            <div class="icon">
              <i class="ik ik-user"></i>
            </div>
            <div class="header-info">
              <span> Gestionnaire principal </span>
              <span> {{user.prenom}} {{user.nom}}</span>
            </div>
          </div>
          <div
            class="right"
            title="Modifier"
            @click="adminSettingState = true"
            v-if="!adminSettingState"
          >
            <i class="ik ik-edit-1"></i>
          </div>

          <div class="confirm" v-else>
            <div @click="adminSettingState = false" title="annuler">
              <i class="ik ik-x"></i>
            </div>
            <div title="enregistrer">
              <i class="ik ik-check"></i>
              <span>Valider</span>
            </div>
          </div>
        </div>
        <div class="block-main" v-if="!adminSettingState">
          <ul>
            <li>
              <span>Poste</span>
              <span>Directeur général</span>
            </li>
            <li>
              <span>N° téléphone</span>
              <span>{{user.tel}}</span>
            </li>
            <li>
              <span>Email</span>
              <span>{{user.email}}</span>
            </li>
            <li>
              <span>Profession</span>
              <span>Agent immobilier</span>
            </li>
          </ul>
        </div>
        <div class="block-main" v-else>
          <ul>
            <li>
              <span>Poste</span>
              <input type="text" value="Directeur général" />
            </li>
            <li>
              <span>N° téléphone</span>
              <input type="text" :value="user.tel" />
            </li>
            <li>
              <span>Email</span>
              <input type="text" :value="user.email" />
            </li>
            <li>
              <span>Profession</span>
              <input type="text" value="Agent immobilier" />
            </li>
          </ul>
        </div>
      </div>
      <div class="info-block">
        <div class="block-header">
          <div class="left">
            <div class="icon">
              <i class="ik ik-loader"></i>
            </div>
            <div class="header-info">
              <span> Préférences </span>
              <span> Informations générales </span>
            </div>
          </div>
          <div class="certified" title="Certified">
            <i class="ik ik-alert-triangle"></i>
            <span>Votre entreprise n'est pas certifiée</span>
            <!-- <span>Votre entreprise est certifiée</span> -->
            <!-- <i class="ik ik-thumbs-up"></i> -->
          </div>
        </div>
        <div class="block-main">
          <div class="first-block">
            <div class="div1">
              <span class="setting-title"> Langue </span>
              <p>Selectionnez la langue de votre choix</p>
              <div class="input">
                <select>
                  <option value=""><span>Français</span></option>
                  <option value=""><span>Anglais</span></option>
                </select>
              </div>
            </div>
            <div class="div2">
              <span class="setting-title"> Dévise </span>
              <p>Selectionnez votre unité monétaire</p>
              <div class="input">
                <select>
                  <option v-for="(devise, cle) in devises" :key="cle" value="">
                    <span
                      >{{ devise.title }} <b>({{ devise.device }})</b></span
                    >
                  </option>
                </select>
              </div>
            </div>
            <div class="div3">
              <span class="setting-title"> Index en eau </span>
              <p>Entrez le prix unitaire par index</p>
              <div class="input">
                <div class="input-value">450Fcfa/m3</div>
                <div class="input-modifier"><i class="ik ik-edit-1"></i></div>
                <!-- <input type="number" min="0" value="450">
                <div class="input-confirm">
                    <i class="ik ik-check"></i>
                </div> -->
              </div>
            </div>
            <div class="div4">
              <span class="setting-title"> Index en lumière </span>
              <p>Entrez le prix unitaire par index</p>
              <div class="input">
                <div class="input-value">600Fcfa/kWatt</div>
                <div class="input-modifier"><i class="ik ik-edit-1"></i></div>
                <!-- <input type="number" min="0" value="600">
                <div class="input-confirm">
                    <i class="ik ik-check"></i>
                </div> -->
              </div>
            </div>
          </div>
          <div class="second-block">
            <div class="abonnement-info"></div>
            <div class="members">
              <span>Toute l'équipe</span>
              <p>L'entreprise est actuellement composé de 10 membres.</p>
              <b>Voir plus</b>
              <div class="members-block">
                <div>
                  <img
                    src="http://localhost:8080/img/profile-picture.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="http://localhost:8080/img/profile-picture.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="http://localhost:8080/img/profile-picture.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="http://localhost:8080/img/profile-picture.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="second-part"></div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      langues: [
        {
          libelle: "Francais",
          country: "cm",
          locale: "fr",
        },
        {
          libelle: "Anglais",
          country: "gb",
          locale: "en",
        },
      ],
      devises: [
        {
          title: "  Franc CFA",
          icon: "fa fa-database",
          device: "F",
        },
        {
          title: "  Euro",
          icon: "fa fa-book",
          device: "€",
        },
        {
          title: "  Dollar",
          icon: "fas fa-dollar-sign",
          device: "$",
        },
        {
          title: "  naira",
          icon: "fa fa-pencil",
          device: "₦",
        },
      ],
      companySettingState: false,
      adminSettingState: false,
      currentCompany:null
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted(){
    console.log(this.user);
    this.currentCompany = this.user.entreprises.find(
        (elt) => elt.idEntreprise == storage.get("selected_enterprise"))
    console.log(this.currentCompany);
  }
};
</script>

<style scoped>
.param-body {
  width: 100%;
  height: auto;
}
.param-body .first-part {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 250px);
}
.first-part > div {
  background: white;
  border-radius: 8px;
}
.company-block {
  grid-area: 1 / 1 / 3 / 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.boss-block {
  grid-area: 3 / 1 / 5 / 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.info-block {
  grid-area: 1 / 3 / 5 / 6;
  display: flex;
  flex-direction: column;
}
.first-part div .block-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgb(243, 243, 243);
  height: 90px;
}
.block-header .left {
  display: flex;
  align-items: center;
}
.block-header .right {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  background: #eee;
  border-radius: 8px;
  cursor: pointer;
}
.confirm {
  display: flex;
  height: 40px;
  width: auto;
}
.confirm div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 40px;
  width: auto;
  min-width: 40px;
  background: #eee;
  border-radius: 8px;
  cursor: pointer;
}
.confirm div:nth-child(2) {
  padding: 0 15px;
  margin-left: 5px;
  background: #191c22;
  color: white;
}
.confirm div:nth-child(2) i {
  margin-right: 5px;
}
.block-header .certified {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 40px;
  width: auto;
  /* background: #eee; */
  border-radius: 8px;
  cursor: pointer;
}
.certified span {
  margin-left: 10px;
  font-size: 0.85rem;
  font-weight: 600;
  text-decoration: underline;
}
.left .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 8px;
  background: #eee;
  font-size: 1.5rem;
  color: rgb(56, 56, 56);
}
.left .header-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 70px);
  height: 100%;
}
.header-info span {
  font-size: 1.1rem;
  font-weight: 600;
}
.header-info span:nth-child(1) {
  font-size: 0.9rem;
  font-weight: normal;
}
.header-info span:nth-child(2)::first-letter {
  text-transform: uppercase;
}
.first-part .company-block .block-main {
  display: block;
  width: 100%;
  height: calc(100% - 90px);
  border-radius: 0 0 8px 8px;
}
.block-main ul {
  padding: 0 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
ul li {
  list-style: none;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(243, 243, 243);
}
ul li:last-child {
  border-bottom: none;
}
ul li span:nth-child(2) {
  font-weight: 600;
}
.info-block .block-main {
  display: block;
  width: 100%;
  height: calc(100% - 90px);
}
.block-main .first-block {
  margin-top: 30px;
  padding: 0 20px;
  width: 100%;
  min-height: 180px;
  height: 40%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}
.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.first-block > div {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 8px;
  border: 1px solid #eee;
  cursor: pointer;
}
.first-block div .input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: #eee;
}
.first-block div .setting-title {
  font-weight: 600;
  font-size: 1.1rem;
  text-align: center;
}
.first-block div p {
  margin-top: 10px;
  text-align: center;
}
.first-block div .input-value {
  font-weight: 500;
}
.input .input-modifier {
  margin-left: 10px;
}
.input select {
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: transparent;
  border: none;
}
.input select option span {
  font-weight: 600;
}
.input input[type="number"] {
  background: transparent;
  border: 1px solid rgb(129, 129, 129);
  width: calc(100% - 45px);
  height: 80%;
  border-radius: 4px;
}
.input .input-confirm {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 30px;
  border-radius: 4px;
  background: #191c22;
  color: #fff;
}
.block-main .second-block {
  margin-top: 30px;
  padding: 30px 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
  width: 100%;
  height: calc(60% - 60px);
  border-top: 1px solid #eee;
  box-sizing: border-box;
  /* background: teal; */
}
.second-block div {
  display: flex;
  height: 100%;
  background: #eee;
  border-radius: 8px;
}
.second-block .abonnement-info {
  grid-area: 1 / 1 / 2 / 5;
}
.second-block .members {
  grid-area: 1 / 5 / 2 / 7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.members span {
  font-weight: bold;
  font-size: 1.3rem;
}
.members p {
  margin: 0;
  text-align: center;
}
.members b {
  margin: 0;
  display: flex;
  align-items: center;
  /* background: teal; */
  text-decoration: underline;
}
.members b i {
  margin-top: 5px;
  margin-left: 5px;
  line-height: 0;
  text-decoration: none;
}
.members .members-block {
  position: relative;
  margin-top: 10px;
  width: auto;
  height: 45px;
  transform: translateX(-60%);
}
.members-block div {
  height: 100%;
  width: 45px;
  border-radius: 50%;
  border: 3px solid white;
}
.members-block div img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.members-block div:nth-child(2) {
  position: absolute;
  top: 0;
  left: 20px;
}
.members-block div:nth-child(3) {
  position: absolute;
  top: 0;
  left: 40px;
}
.members-block div:nth-child(4) {
  position: absolute;
  top: 0;
  left: 60px;
}
</style>